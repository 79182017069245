.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .btn {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  cursor: pointer;
}

.btn:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
} */

/*

TemplateMo 590 topic listing

https://templatemo.com/tm-590-topic-listing

*/

/*---------------------------------------
  CUSTOM PROPERTIES ( VARIABLES )             
-----------------------------------------*/
:root {
  --white-color:                  #ffffff;
  --primary-color:                #75a17d;
  --secondary-color:              #c9d4b8;
  --section-bg-color:             #f0f8ff;
  --custom-btn-bg-color:          #4e6642;
  --custom-btn-bg-hover-color:    #9fa891;
  --dark-color:                   #000000;
  --p-color:                      #717275;
  --border-color:                 #ffffff;
  --link-hover-color:             #9fa891;

  --body-font-family:             'Open Sans', sans-serif;
  --title-font-family:            'Montserrat', sans-serif;

  --h1-font-size:                 58px;
  --h2-font-size:                 46px;
  --h3-font-size:                 32px;
  --h4-font-size:                 28px;
  --h5-font-size:                 24px;
  --h6-font-size:                 22px;
  --p-font-size:                  20px;
  --menu-font-size:               14px;
  --btn-font-size:                18px;
  --copyright-font-size:          16px;

  --border-radius-large:          100px;
  --border-radius-medium:         20px;
  --border-radius-small:          10px;

  --font-weight-normal:           400;
  --font-weight-medium:           500;
  --font-weight-semibold:         600;
  --font-weight-bold:             700;
}

body {
  /* background-color: var(--white-color); */
  background-color: #ccc !important;
  font-family: var(--body-font-family); 
}

table {
  color: #fff !important;
}
/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/

h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font-family); 
  font-weight: var(--font-weight-semibold);
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--font-weight-bold);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-bold);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  color: var(--primary-color);
  font-size: var(--h6-font-size);
}

p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-normal);
}

ul li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-normal);
}

a, 
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  display: inline-block;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}


/*---------------------------------------
  SECTION               
-----------------------------------------*/
.section-padding {
  padding-top: 30px;
  padding-bottom: 10px;
}

.section-bg {
  background-color: var(--section-bg-color);
}

.section-overlay {
  background-image: linear-gradient(15deg, #4e6642 0%, #9fa891 100%);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 0.85;
}

.section-overlay + .container {
  position: relative;
}

.tab-content {
  background-color: var(--white-color);
  border-radius: var(--border-radius-medium);
}

.nav-tabs {
  border-bottom: 1px solid #ecf3f2;
  margin-bottom: 40px;
  justify-content: center;
}

.nav-tabs .nav-link {
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  color: var(--p-color);
  font-family: var(--title-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--font-weight-medium);
  padding: 15px 25px;
  transition: all 0.3s;
}

.nav-tabs .nav-link:first-child {
  margin-right: 20px;
}

.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus, 
.nav-tabs .nav-link:hover {
  border-bottom-color: var(--primary-color);
  color: var(--primary-color);
}


/*---------------------------------------
  CUSTOM ICON COLOR               
-----------------------------------------*/
.custom-icon {
  color: var(--secondary-color);
}


/*---------------------------------------
  CUSTOM BUTTON               
-----------------------------------------*/
.custom-btn {
  background: var(--custom-btn-bg-color);
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-size: var(--btn-font-size);
  font-weight: var(--font-weight-semibold);
  line-height: normal;
  transition: all 0.3s;
  padding: 10px 20px;
}

.custom-btn:hover {
  background: var(--custom-btn-bg-hover-color);
  color: var(--white-color);
}

.custom-border-btn {
  background: transparent;
  border: 2px solid var(--custom-btn-bg-color);
  color: var(--custom-btn-bg-color);
}

.custom-border-btn:hover {
  background: var(--custom-btn-bg-color);
  border-color: transparent;
  color: var(--white-color);
}

.custom-btn-bg-white {
  border-color: var(--white-color);
  color: var(--white-color);
}


/*---------------------------------------
  SITE HEADER              
-----------------------------------------*/
.site-header {
  background-image: linear-gradient(15deg, #4e6642 0%, #9fa891 100%);
  padding-top: 150px;
  padding-bottom: 80px;
}

.site-header .container {
  height: 100%;
}

.breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-item a:hover,
.breadcrumb-item.active {
  color: var(--white-color);
}

.site-header .custom-icon {
  color: var(--white-color);
  font-size: var(--h4-font-size);
}

.site-header .custom-icon:hover {
  color: var(--secondary-color);
}

/*---------------------------------------
  My New Nav
-----------------------------------------*/
main { min-width: 320px; }
.main-nav {
    background: #fff;
}
.main-nav img {
    width: 300px;
    max-width: 100%;
    height: auto !important;
    padding: 15px 0;
}
.grid {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
	justify-content: center;
}
.grid-item {
    min-height: 150px;
	margin: 20px 10px 0;
    background: #fff;
    border: 1px solid #b86223;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.grid-item.two {
	width: calc(50% - 20px);
    -webkit-flex: 0;
    -ms-flex: 0 0 calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid-item a {
    width: 100%;
    height: 100%;
    padding: 15px;
    color: #b86223;
    text-decoration: none;
    font-size: 16px; font-size: 1.6rem; 
	line-height: 20px; line-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*---------------------------------------
  NAVIGATION              
-----------------------------------------*/
.sticky-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.sticky-wrapper.is-sticky .navbar {
  background-color: var(--secondary-color);
}

.navbar {
  background: transparent;
  border-bottom: 1px solid rgba(128, 208, 199, 0.35);
  z-index: 9;
}

.navbar-brand,
.navbar-brand:hover {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-bold);
  display: block;
}

.navbar-brand span {
  font-family: var(--title-font-family);
}

.navbar-expand-lg .navbar-nav .nav-link {
  border-radius: var(--border-radius-large);
  margin: 10px;
  padding: 10px;
}

.navbar-nav .nav-link {
  display: inline-block;
  color: var(--white-color);
  font-family: var(--title-font-family); 
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-nav .nav-link.active, 
.navbar-nav .nav-link:hover {
  color: var(--primary-color);
}

.navbar .dropdown-menu {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  border: 0;
  display: inherit;
  opacity: 0;
  min-width: 9rem;
  margin-top: 20px;
  padding: 13px 0 10px 0;
  transition: all 0.3s;
  pointer-events: none;
}

.navbar .dropdown-menu::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--white-color);
  position: absolute;
  top: -10px;
  left: 10px;
}

.navbar .dropdown-item {
  display: inline-block;
  color: var(--p-bg-color);
  font-family: var(--title-font-family); 
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
}

.navbar .dropdown-item.active, 
.navbar .dropdown-item:active,
.navbar .dropdown-item:focus, 
.navbar .dropdown-item:hover {
  background: transparent;
  color: var(--primary-color);
}

.navbar .dropdown-toggle::after {
  content: "\f282";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: var(--copyright-font-size);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  left: 2px;
  border: 0;
}

@media screen and (min-width: 992px) {
  .navbar .dropdown:hover .dropdown-menu {
    opacity: 1;
    margin-top: 0;
    pointer-events: auto;
  }
}

.navbar-icon {
  background: var(--white-color);
  border-radius: var(--border-radius-large);
  display: inline-block;
  font-size: var(--h5-font-size);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.3s ease;
}

.navbar-icon:hover {
  background: var(--primary-color);
  color: var(--white-color);
}

.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  height: 35px;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--white-color);
  transition: background 10ms 300ms ease;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--white-color);
  width: 30px;
  height: 2px;
  content: '';
}

.navbar-toggler .navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon::after {
  top: 8px;
}


/*---------------------------------------
  HERO        
-----------------------------------------*/
.hero-section {
  background-image: linear-gradient(15deg, #3e5743 0%, #80a687 100%);
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 150px;
}

.hero-section .input-group {
  background-color: var(--white-color);
  border-radius: var(--border-radius-large);
  padding: 10px 15px;
}

.hero-section .input-group-text {
  background-color: transparent;
  border: 0;
}

.hero-section input[type="search"] {
  border: 0;
  box-shadow: none;
  margin-bottom: 0;
  padding-left: 0;
}

.hero-section button[type="submit"] {
  background-color: var(--primary-color);
  border: 0;
  border-radius: var(--border-radius-large) !important;
  color: var(--white-color);
  max-width: 150px;
}


/*---------------------------------------
  TOPICS              
-----------------------------------------*/
.featured-section {
  background-color: var(--secondary-color);
  border-radius: 0 0 100px 100px;
  padding-bottom: 100px;
}

.featured-section .row {
  position: relative;
  bottom: 100px;
  margin-bottom: -100px;
}

.custom-block {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  padding: 30px;
  transition: all 0.3s ease;
  height: 100%;
}

.custom-block:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
}

.custom-block > a {
  width: 100%;
}

.custom-block-image {
  display: block;
  /* width: 100%; */
  /* height: 200px; */
  object-fit: cover;
  margin-top: 35px;
}

.custom-block .rounded-pill {
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 20px;
}

.custom-block-overlay {
  height: 100%;
  min-height: 350px;
  padding: 0;
}

.custom-block-overlay > a {
  height: 100%;
}

.custom-block-overlay .custom-block-image {
  border-radius: var(--border-radius-medium);
  display: block;
  height: 100%;
  margin-top: 0;
}

.custom-block-overlay-text {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  padding: 30px;
}

.social-share {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 20px 35px;
}

.social-share .bi-bookmark {
  color: var(--white-color);
  font-size: var(--h5-font-size);
}

.social-share .bi-bookmark:hover {
  color: var(--secondary-color);
}

.bg-design {
  background-color: #4a7051;
}

.bg-graphic {
  background-color: #4a7051;
}

.bg-advertising {
  background-color: #4a7051;
}

.bg-finance {
  background-color: #4a7051;
}

.bg-music {
  background-color: #4a7051;
}

.bg-education {
  background-color: #4a7051;
}


/*---------------------------------------
  TOPICS               
-----------------------------------------*/
.topics-detail-block {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
}

.topics-detail-block-image {
  display: block;
  border-radius: var(--border-radius-medium);
}

blockquote {
  background-color: var(--section-bg-color);
  border-radius: var(--border-radius-small);
  font-family: var(--title-font-family);
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
  display: inline-block;
  text-align: center;
  margin: 30px;
  padding: 40px;
}

.topics-listing-page .site-header {
  padding-bottom: 65px;
}

.custom-block-topics-listing-info {
  margin: 30px 20px 20px 30px;
}

.custom-block-topics-listing {
  height: inherit;
}

.custom-block-topics-listing .custom-block-image {
  width: 200px;
}


/*---------------------------------------
  PAGINATION              
-----------------------------------------*/
.pagination {
  margin-top: 40px;
}

.page-link {
  border: 0;
  border-radius: var(--border-radius-small);
  color: var(--p-color);
  font-family: var(--title-font-family);
  margin: 0 5px;
  padding: 10px 20px;
}

.page-link:hover,
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.page-item:first-child .page-link {
  margin-right: 10px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background-color: var(--section-bg-color);
  border-radius: var(--border-radius-small);
}

.active>.page-link, .page-link.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}


/*---------------------------------------
  TIMELINE              
-----------------------------------------*/
.timeline-section {
  /* background-image: url('../images/colleagues-working-cozy-office-medium-shot.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.timeline-container .vertical-scrollable-timeline {
  list-style-type: none;
  position: relative;
  padding-left: 0;
}

.timeline-container .vertical-scrollable-timeline .list-progress {
  width: 8px;
  height: 87%;
  background-color: var(--primary-color);
  position: absolute;
  left: 52px;
  top: 0;
  overflow: hidden;
}

.timeline-container .vertical-scrollable-timeline .list-progress .inner {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--secondary-color);
  width: 100%;
}

.timeline-container .vertical-scrollable-timeline li {
  position: relative;
  padding: 20px 0px 65px 145px;
}

.timeline-container .vertical-scrollable-timeline li:last-child {
  padding-bottom: 0;
}

.timeline-container .vertical-scrollable-timeline li p {
  line-height: 40px;
}

.timeline-container .vertical-scrollable-timeline li p:last-child {
  margin-bottom: 0;
}

.timeline-container .vertical-scrollable-timeline li .icon-holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 104px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 50%;
  z-index: 1;
  transition: 0.4s all;
}

.timeline-container .vertical-scrollable-timeline li .icon-holder::before {
  content: "";
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  position: absolute;
  background-color: var(--secondary-color);
  border-radius: 50%;
  z-index: -1;
  transition: 0.4s all;
}

.timeline-container .vertical-scrollable-timeline li .icon-holder i {
  font-size: 25px;
  color: var(--white-color);
}

.timeline-container .vertical-scrollable-timeline li::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 8px;
  background-color: transparent;
  left: 52px;
  z-index: 0;
}

.timeline-container .vertical-scrollable-timeline li.active .icon-holder {
  background-color: var(--primary-color);
}

.timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
  background-color: var(--primary-color);
}


/*---------------------------------------
  FAQs              
-----------------------------------------*/
.faq-section .accordion-item {
  border: 0;
}

.faq-section .accordion-button {
  font-size: var(--h6-font-size);
  font-weight: var(--font-weight-semibold);
}

.faq-section .accordion-item:first-of-type .accordion-button {
  border-radius: var(--border-radius-large);
}

.faq-section .accordion-button:not(.collapsed) {
  border-radius: var(--border-radius-large);
  box-shadow: none;
  color: var(--primary-color);
}

.faq-section .accordion-body {
  color: var(--p-color);
  font-size: var(--btn-font-size);
  line-height: 40px;
}

/*---------------------------------------
  NEWSLETTER               
-----------------------------------------*/
.newsletter-image {
  border-radius: var(--border-radius-medium);
}


/*---------------------------------------
  CONTACT               
-----------------------------------------*/
.google-map {
  border-radius: var(--border-radius-medium);
}

.contact-form .form-floating>textarea {
  border-radius: var(--border-radius-medium);
  height: 150px;
}


/*---------------------------------------
  SUBSCRIBE FORM               
-----------------------------------------*/
.subscribe-form-wrap {
  padding: 50px;
}

.subscribe-form {
  width: 95%;
}


/*---------------------------------------
  CUSTOM FORM               
-----------------------------------------*/
.custom-form .form-control {
  border-radius: var(--border-radius-large);
  color: var(--p-color);
  margin-bottom: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  outline: none;
}

.form-floating>label {
  padding-left: 20px;
}

.custom-form button[type="submit"] {
  background: var(--custom-btn-bg-color);
  border: none;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-family: var(--title-font-family);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-semibold);
  transition: all 0.3s;
  margin-bottom: 0;
}

.custom-form button[type="submit"]:hover,
.custom-form button[type="submit"]:focus {
  background: var(--custom-btn-bg-hover-color);
  border-color: transparent;
}


/*---------------------------------------
  SITE FOOTER              
-----------------------------------------*/
.site-footer {
  border-bottom: 10px solid var(--secondary-color);
  position: relative;
}

.site-footer::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 200px 200px;
  border-color: transparent transparent var(--secondary-color) transparent;
  pointer-events: none;
}

.site-footer-title {
  color: var(--primary-color); 
}

.site-footer .dropdown-menu {
  padding: 0;
}

.site-footer .dropdown-item {
  color: var(--p-color);
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  padding: 4px 18px;
}

.site-footer .dropdown-item:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.site-footer .dropdown-menu li:first-child .dropdown-item {
  padding-top: 10px;
}

.site-footer .dropdown-menu li:last-child .dropdown-item {
  padding-bottom: 12px;
}

.site-footer .dropdown-toggle {
  background-color: var(--secondary-color);
  border-color: var(--white-color);
}

.site-footer .dropdown-toggle:hover {
  background-color: var(--primary-color);
  border-color: transparent;
}

.site-footer-links {
  padding-left: 0;
}

.site-footer-link-item {
  display: block;
  list-style: none;
  line-height: normal;
}

.site-footer-link {
  color: var(--secondary-color);
  font-size: var(--copyright-font-size);
  font-weight: var(--font-weight-medium);
  line-height: normal;
}

.copyright-text {
  font-size: var(--copyright-font-size);
}


/*---------------------------------------
  SOCIAL ICON               
-----------------------------------------*/
.social-icon {
  margin: 0;
  padding: 0;
}

.social-icon-item {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.social-icon-link {
  background: var(--secondary-color);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-size: var(--copyright-font-size);
  display: block;
  margin-right: 10px;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 36px;
  transition: background 0.2s, color 0.2s;
}

.social-icon-link:hover {
  background: var(--primary-color);
  color: var(--white-color);
}


/*---------------------------------------
  RESPONSIVE STYLES               
-----------------------------------------*/
@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .navbar {
    background-color: var(--secondary-color);
  }

  .navbar-nav .dropdown-menu {
    position: relative;
    left: 10px;
    opacity: 1;
    pointer-events: auto;
    max-width: 155px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .navbar-expand-lg .navbar-nav {
    padding-bottom: 20px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
  }

  .nav-tabs .nav-link:first-child {
    margin-right: 5px;
  }

  .nav-tabs .nav-link {
    font-size: var(--copyright-font-size);
    padding: 10px;
  }

  .featured-section {
    border-radius: 0 0 80px 80px;
    padding-bottom: 50px;
  }

  .custom-block-topics-listing .custom-block-image {
    width: auto;
  }

  .custom-block-topics-listing > .d-flex,
  .custom-block-topics-listing-info,
  .custom-block-topics-listing a {
    flex-direction: column;
  }

  .timeline-container .vertical-scrollable-timeline .list-progress {
    height: 75%;
  }

  .timeline-container .vertical-scrollable-timeline li {
    padding-left: 135px;
  }

  .subscribe-form-wrap {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }
}





/* LOGIN SCREEN */
.login-box {
  margin-top: 2rem;
  width: 80%;
  border: #ddd 1px solid;
  margin-left: auto;
  margin-right: auto;
  background-color: #9fa891;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 0px solid #000000;
  font: 300 15px / 1em 'Open Sans', sans-serif;
  opacity: 0.9;
  height: 18rem;
  padding-top:5rem;
  padding-bottom:5rem;
  max-width: 512px;
}

.login-group {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 15px;;
}

.login-input {
  margin: 5px;
}


.login-btn {
  background: #a0a896;
  background-image: -webkit-linear-gradient(top, #a0a896, #4e6642);
  background-image: -moz-linear-gradient(top, #a0a896, #4e6642);
  background-image: -ms-linear-gradient(top, #a0a896, #4e6642);
  background-image: -o-linear-gradient(top, #a0a896, #4e6642);
  background-image: linear-gradient(to bottom, #a0a896, #4e6642);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.logo-header {
  width: 50%;
  max-width: 512px;
}

.login-btn:hover {
  background: #d7e096;
  background-image: -webkit-linear-gradient(top, #d7e096, #848a54);
  background-image: -moz-linear-gradient(top, #d7e096, #848a54);
  background-image: -ms-linear-gradient(top, #d7e096, #848a54);
  background-image: -o-linear-gradient(top, #d7e096, #848a54);
  background-image: linear-gradient(to bottom, #d7e096, #848a54);
  text-decoration: none;
}

.login-header {
  font-size: 30px;
  line-height: 30px;
}

/* MAIN CONTENT AREA */
.main-content {
  border: #ddd 1px solid;
  background-color: #9fa891;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 0px solid #000000;
  opacity: 0.9;
  padding-top:5rem;

}

.main-btn {
  background: #a0a896;
  background-image: -webkit-linear-gradient(top, #a0a896, #4e6642);
  background-image: -moz-linear-gradient(top, #a0a896, #4e6642);
  background-image: -ms-linear-gradient(top, #a0a896, #4e6642);
  background-image: -o-linear-gradient(top, #a0a896, #4e6642);
  background-image: linear-gradient(to bottom, #a0a896, #4e6642);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  padding: 1rem;
  text-decoration: none;
  width: 6em;
  height: 5em;
}

.main-btn:hover {
  background: #d7e096;
  background-image: -webkit-linear-gradient(top, #d7e096, #848a54);
  background-image: -moz-linear-gradient(top, #d7e096, #848a54);
  background-image: -ms-linear-gradient(top, #d7e096, #848a54);
  background-image: -o-linear-gradient(top, #d7e096, #848a54);
  background-image: linear-gradient(to bottom, #d7e096, #848a54);
  text-decoration: none;
}

.row-spacer {
  margin-top: 2rem !important;
}

.btn-spacer {
  margin-top: 2rem !important;
}

.ReactTable .rt-table {
  padding-bottom: 20px;
  -ms-overflow-style: scrollbar;
}

.ReactTable .-pagination .-previous:hover,
.ReactTable .-pagination .-next:hover {
  background-color: #3f51b5;
  border-radius: 3px;
  -moz-focus-inner {
    border: 0;
  }
}

.ReactTable .-loading > div {
  top: 50%;
  left: 50%;
  color: rgba(63, 81, 181, 1);
}

.BoldText {
  font-weight: bold;
}

.ColoredText {
  color : #3f51b5;
}

.LeftAlignedText {
  text-align: left;
}

.dropzone_window {
  border: 1px black dotted;
  height: 5rem;
  padding-top: 1rem;
  background-color: #fff;
}

#files_table td {
  font-size: smaller;
}

.spinner {
  width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #dbdcef;
    border-right-color: #c9d3bf;
    animation: spinner-d3wgkg 1s infinite linear;
    float: right;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
}

@keyframes spinner-d3wgkg {
  to {
     transform: rotate(1turn);
  }
}

#filelist li {
  font-size: smaller;
  list-style-type: none; /* Remove bullets */
}

.listfile {
  width: 14rem;
    margin-right: auto;
    margin-left: auto;
}